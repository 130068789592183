@import "globals";

h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 1.5em;
}

h2 {
  font-family: "Work Sans", sans-serif;
}

.mockupContainer {
  display: inline-block;
  width: 100%;
  // max-width: 700px;
}

.default-nav {
  visibility: hidden;
}

.each-slide {
  height: 60vh !important;
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  // height: px;
}

.slideImg {
  max-height: 100%;
}

.tagline {
  font-family: "Open Sans", sans-serif;
  font-size: 2.5em;
  text-align: left;
}

.siteTitle {
  font-family: "Work Sans", sans-serif;
  font-size: 1.7em;
  color: $primary;
  transition: 0.3s;
}

.contactButton {
  left: 50%;
  margin-top: 5em;
  margin-bottom: 10em;
  background-color: $primary;
  border: none;
  padding: 0.7em;
  border-radius: 20px;
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 1.5em;
}

.contactButton:hover {
  cursor: pointer;
}

.siteTitle:hover {
  color: $primary;
  font-family: "Work Sans", sans-serif;
  cursor: pointer;
}

.headerLogo {
  max-width: 3em;
  margin-right: 1em;
  border-radius: 10px;
  display: inline-block;
}

.header {
  width: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-60%, 0);
}

.servicesContainer {
  max-width: 50em !important;
  margin: auto;
  margin-top: 2em;
}

.servicePane {
  // background-color: $light;
  border: 5px solid $bg;
  // color: $dark;
  transition: 0.5s;
  text-align: center;
  align-content: center;
  display: flex;
  align-items: center; /* vertically center aligns content */
  justify-content: center; /* horizontally center aligns content */
  padding-top: 1em;

  h2 {
    color: $dark;
    margin-bottom: 1.2em;
  }
  border-radius: 15px;
}

.servicePane:hover {
  background-color: $primary;
  border: 5px solid $primary;
  border-radius: 15px;
  cursor: pointer;

  h2 {
    color: $bg;
  }
}

.selectedServicePane {
  background-color: $primary;
  border: 5px solid $primary;
  border-radius: 15px;
  cursor: pointer;

  h2 {
    color: $bg;
  }
}

.matt {
  max-width: 12em;
}

.rainbow_text {
  display: inline-block;
  font-size: 50px;
  font-family: Arial, Helvetica, sans-serif;
  background: black;
  // background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  background: linear-gradient(to right, $primary, $secondary, blue, magenta, cyan);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.rainbow_text.animated {
  animation: rainbow_animation 10s infinite;
  background-size: 400%;
}

@keyframes rainbow_animation {

  0%,
  100% {
    background-position: 0
  }

  50% {
    background-position: 100%
  }
}

@media (max-width: 500px) {
  .siteTitle {
    margin-top: 0.1em;
    margin-left: -1.8em;
  }

  .header {
    width: 70%;
  }

  .headerLogo {
    max-width: 2em;
    margin-right: 2.7em;
  }
}
