@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Work+Sans:wght@800&display=swap");
@import "react-slideshow-image/dist/styles.css";

$primary: rgb(0, 176, 103);
$secondary: rgb(133, 238, 133);
$bg: white;
$light: rgb(207, 207, 207);
$dark: rgb(48, 48, 48);

html {
  background-color: $bg;
  margin: 3em;
  color: $dark;
}
